export const colors = ["orange", "blue", "pink", "green", "red", "yellow"] as const;

export const variants = ["light", "lighter", "dark", "darker"] as const;

export type Color = (typeof colors)[number];

export type ColorVariant = (typeof variants)[number];

export const getCssVariable = function (color: Color, variant?: ColorVariant) {
  if (variant) return `var(--${color}-${variant})`;
  else return `var(--${color}})`;
};

export const getColorFromKey = (text: string): Color => {
  // Sum the ASCII values of characters in the user ID
  const asciiSum = text.split("").reduce((sum, char) => sum + char.charCodeAt(0), 0);

  // Calculate the index by taking the modulo of the sum with the length of the colors array
  const index = asciiSum % colors.length;

  // Return the color at the calculated index
  return colors[index];
};
